<template>
  <div id="container" class="clearfix">
    <section ref="contactformcontrol" class="contactinfo centervertical">
      <div class="valigncontent">
        <h2 class="title titlesize-45 icon-before seperator-after">
          <p>How to reach us</p>
        </h2>
        <ul class="infolist">
          <li class="phone icon-before">
            <a title="Phone" href="tel:1592">1592</a>
          </li>
          <li class="fax icon-before">
            <a title="Fax" href="tel:09 851 200">09 851 200</a>
          </li>
          <li class="email icon-before">
            <a title="Email" href="mailto:office@malakaltawouk.com"
              >office@malakaltawouk.com</a
            >
          </li>
        </ul>
        <span class="caption titlesize-25">We want to hear from you!</span>
        <span class="blackbtn messageusbtn" @click="ToggleMessageUs()"
          >message us</span
        >
      </div>
    </section>
    <aside class="messageus centervertical">
      <div class="valigncontent">
        <span class="closecontactform closeform" @click="handleClose()"></span>
        <form
          @submit.prevent="handlesubmit"
          ref="contactform"
          id="contact_form"
          class="contactform icon-before"
        >
          <h2 class="title titlesize-25">Message Us</h2>
          <div class="inputfield">
            <label class="inputlabel" for="contactfullname">Full Name</label>
            <input class="input" name="name" required v-model="name" />
          </div>
          <div class="inputfield">
            <label class="inputlabel" for="contactsemail">Email</label>
            <input
              class="input"
              type="email"
              name="email"
              required
              v-model="email"
            />
          </div>

          <div class="inputfield area">
            <textarea
              class="textarea"
              name="message"
              placeholder="Message"
              v-model="message"
              required
            ></textarea>
          </div>
          <input type="submit" class="blackbtn send loaderbtn" />
        </form>
        <span class="contactmessage titlesize-30"
          >Thank You, We Will Get Back To You Soon!</span
        >
      </div>
    </aside>
  </div>
</template>
<script>
import vueRecaptcha from 'vue3-recaptcha2'
import Contactus from '@/forms/Contactus'
import { SITEKEY } from '@/constants/recaptcha'
export default {
  props: {
    isOpen: Boolean,
    title: String,
  },
  components: {
    vueRecaptcha,
  },
  data() {
    return {
      isOpen: this.isOpen,
      title: this.title,
      showRecaptcha: false,
      SITEKEY: SITEKEY,
      //form Data
      name: '',
      email: '',
      message: '',
    }
  },
  computed: {
    getTitle() {
      return this.title
    },
  },
  methods: {
    handlesubmit() {
      Contactus(this.name, this.email, this.message)
    },
    handleClose() {
      this.$refs.contactformcontrol.classList.remove('open')
    },
    ToggleMessageUs() {
      this.$refs.contactformcontrol.classList.add('open')
    },
    recaptchaVerified(response) {},
    recaptchaExpired() {
      this.$refs.vueRecaptcha.reset()
    },
    recaptchaFailed() {},
  },
}
</script>
