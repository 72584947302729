import axios from 'axios'
import { API_URL } from '@/constants/axios'

function sendForm(name, email, message) {
  var bodyFormData = new FormData()
  bodyFormData.append('name', name)
  bodyFormData.append('email', email)
  bodyFormData.append('message', message)
  axios({
    method: 'post',
    url: `${API_URL}/contact`,
    data: bodyFormData,
  })
    .then(function (response) {
      alert('Form sent!')
    })
    .catch(function (error) {
      //handle error
      alert(error?.response?.data?.message.split('.')[0])
      console.log(response)
    })
}
export default sendForm
