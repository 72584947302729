import { API_URL } from '@/constants/axios'
import axios from 'axios'
const state = { items: [] }

const getters = {
  allItems: (state) => state.items,
}

const actions = {
  // fetch all Items
  async fetchAllItems({ commit }) {
    try {
      var result = await axios({
        method: 'GET',
        url: `${API_URL}/items`,
      })
      if (result.data !== null) {
        var items = result.data
      }
    } catch (error) {
      console.error(error)
    }

    commit('setItems', items)
  },
}
const mutations = {
  setItems: (state, items) => (state.items = items),
}

export default {
  state,
  getters,
  actions,
  mutations,
}
