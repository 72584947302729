<template>
  <div id="container" class="clearfix">
    <section class="ourmenu branches_section">
      <h2 class="maintitle titlesize-45">OUR BRANCHES</h2>

      <div class="foodcontainer centervertical">
        <div class="swiper-container foodswiper valigncontent">
          <div class="swiper-wrapper">
            <div class="swiper-slide slide">
              <div class="frame">
                <img alt="Abraj" src="/assets/images/ImageNotAvailable.jpg"/>
              </div>
              <h3 class="title titlesize-25">Abraj</h3>
              <span class="description">
                <p></p>
              </span>
              <span class="price text_align_right"></span>
            </div>
            <div class="swiper-slide slide">
              <div class="frame">
                <img alt="Aley" src="/assets/images/ImageNotAvailable.jpg" />
              </div>
              <h3 class="title titlesize-25">Aley</h3>
              <span class="description">
                <p></p>
              </span>
              <span class="price text_align_right"></span>
            </div>
            <div class="swiper-slide slide">
              <div class="frame">
                <img alt="Batroun" src="/assets/images/ImageNotAvailable.jpg" />
              </div>
              <h3 class="title titlesize-25">Batroun</h3>
              <span class="description">
                <p></p>
              </span>
              <span class="price text_align_right"></span>
            </div>
            <div class="swiper-slide slide">
              <div class="frame">
                <img alt="Bikfaya " src="/assets/images/ImageNotAvailable.jpg" />
              </div>
              <h3 class="title titlesize-25">Bikfaya</h3>
              <span class="description">
                <p></p>
              </span>
              <span class="price text_align_right"></span>
            </div>
            <div class="swiper-slide slide">
              <div class="frame">
                <img alt="Bliss" src="/assets/images/ImageNotAvailable.jpg" />
              </div>
              <h3 class="title titlesize-25">Bliss</h3>
              <span class="description">
                <p></p>
              </span>
              <span class="price text_align_right"></span>
            </div>
            <div class="swiper-slide slide">
              <div class="frame">
                <img alt="Broumana" src="/assets/images/ImageNotAvailable.jpg" />
              </div>
              <h3 class="title titlesize-25">Broumana</h3>
              <span class="description">
                <p></p>
              </span>
              <span class="price text_align_right"></span>
            </div>
            <div class="swiper-slide slide">
              <div class="frame">
                <img alt="Checkka" src="/assets/images/ImageNotAvailable.jpg" />
              </div>
              <h3 class="title titlesize-25">Checkka</h3>
              <span class="description">
                <p></p>
              </span>
              <span class="price text_align_right"></span>
            </div>
            <div class="swiper-slide slide">
              <div class="frame">
                <img alt="Chtoura" src="/assets/images/ImageNotAvailable.jpg" />
              </div>
              <h3 class="title titlesize-25">Chtoura</h3>
              <span class="description">
                <p></p>
              </span>
              <span class="price text_align_right"></span>
            </div>
            <div class="swiper-slide slide">
              <div class="frame">
                <img alt="Daher el ain" src="/assets/images/ImageNotAvailable.jpg" />
              </div>
              <h3 class="title titlesize-25">Daher el ain</h3>
              <span class="description">
                <p></p>
              </span>
              <span class="price text_align_right"></span>
            </div>
            <div class="swiper-slide slide">
              <div class="frame">
                <img alt="DEKWENEH" src="/assets/images/ImageNotAvailable.jpg" />
              </div>
              <h3 class="title titlesize-25">Dekweneh</h3>
              <span class="description">
                <p></p>
              </span>
              <span class="price text_align_right"></span>
            </div>
            <div class="swiper-slide slide">
              <div class="frame">
                <img alt="DORA" src="/assets/images/ImageNotAvailable.jpg" />
              </div>
              <h3 class="title titlesize-25">Dora</h3>
              <span class="description">
                <p></p>
              </span>
              <span class="price text_align_right"></span>
            </div>
            <div class="swiper-slide slide">
              <div class="frame">
                <img alt="HADATH" src="/assets/images/ImageNotAvailable.jpg" />
              </div>
              <h3 class="title titlesize-25">Hadath</h3>
              <span class="description">
                <p></p>
              </span>
              <span class="price text_align_right"></span>
            </div>
            <div class="swiper-slide slide">
              <div class="frame">
                <img alt="HARET SAKHER" src="/assets/images/ImageNotAvailable.jpg" />
              </div>
              <h3 class="title titlesize-25">Haret sakher</h3>
              <span class="description">
                <p></p>
              </span>
              <span class="price text_align_right"></span>
            </div>
            <div class="swiper-slide slide">
              <div class="frame">
                <img alt="JAL EL DIB" src="/assets/images/ImageNotAvailable.jpg" />
              </div>
              <h3 class="title titlesize-25">Jal el dib</h3>
              <span class="description">
                <p></p>
              </span>
              <span class="price text_align_right"></span>
            </div>
            <div class="swiper-slide slide">
              <div class="frame">
                <img alt="JBEIL" src="/assets/images/ImageNotAvailable.jpg" />
              </div>
              <h3 class="title titlesize-25">Jbeil</h3>
              <span class="description">
                <p></p>
              </span>
              <span class="price text_align_right"></span>
            </div>
            <div class="swiper-slide slide">
              <div class="frame">
                <img alt="JOUNIEH" src="/assets/images/ImageNotAvailable.jpg" />
              </div>
              <h3 class="title titlesize-25">Jounieh</h3>
              <span class="description">
                <p></p>
              </span>
              <span class="price text_align_right"></span>
            </div>
            <div class="swiper-slide slide">
              <div class="frame">
                <img alt="KHALDEH" src="/assets/images/ImageNotAvailable.jpg" />
              </div>
              <h3 class="title titlesize-25">Khaldeh</h3>
              <span class="description">
                <p></p>
              </span>
              <span class="price text_align_right"></span>
            </div>
            <div class="swiper-slide slide">
              <div class="frame">
                <img alt="MAAMELTEIN" src="/assets/images/ImageNotAvailable.jpg" />
              </div>
              <h3 class="title titlesize-25">Maameltein</h3>
              <span class="description">
                <p></p>
              </span>
              <span class="price text_align_right"></span>
            </div>
            <div class="swiper-slide slide">
              <div class="frame">
                <img alt="MANSOURIEH " src="/assets/images/ImageNotAvailable.jpg" />
              </div>
              <h3 class="title titlesize-25">Mansourieh</h3>
              <span class="description">
                <p></p>
              </span>
              <span class="price text_align_right"></span>
            </div>
            <div class="swiper-slide slide">
              <div class="frame">
                <img alt="MAZRAAT YACHOUH" src="/assets/images/ImageNotAvailable.jpg" />
              </div>
              <h3 class="title titlesize-25">Mazraat yachouh</h3>
              <span class="description">
                <p></p>
              </span>
              <span class="price text_align_right"></span>
            </div>
            <div class="swiper-slide slide">
              <div class="frame">
                <img alt="OKAIBE" src="/assets/images/ImageNotAvailable.jpg" />
              </div>
              <h3 class="title titlesize-25">Okaibe</h3>
              <span class="description">
                <p></p>
              </span>
              <span class="price text_align_right"></span>
            </div>
            <div class="swiper-slide slide">
              <div class="frame">
                <img alt="RAYFOUN" src="/assets/images/ImageNotAvailable.jpg" />
              </div>
              <h3 class="title titlesize-25">Rayfoun</h3>
              <span class="description">
                <p></p>
              </span>
              <span class="price text_align_right"></span>
            </div>
            <div class="swiper-slide slide">
              <div class="frame">
                <img alt="SAIDA" src="/assets/images/ImageNotAvailable.jpg" />
              </div>
              <h3 class="title titlesize-25">Saida</h3>
              <span class="description">
                <p></p>
              </span>
              <span class="price text_align_right"></span>
            </div>
            <div class="swiper-slide slide">
              <div class="frame">
                <img alt="TRIPOLI" src="/assets/images/ImageNotAvailable.jpg" />
              </div>
              <h3 class="title titlesize-25">Tripoli</h3>
              <span class="description">
                <p></p>
              </span>
              <span class="price text_align_right"></span>
            </div>
            <div class="swiper-slide slide">
              <div class="frame">
                <img alt="ZGHARTA " src="/assets/images/ImageNotAvailable.jpg" />
              </div>
              <h3 class="title titlesize-25">Zgharta</h3>
              <span class="description">
                <p></p>
              </span>
              <span class="price text_align_right"></span>
            </div>
            <div class="swiper-slide slide">
              <div class="frame">
                <img alt="ZOUK MOSBEH" src="/assets/images/ImageNotAvailable.jpg" />
              </div>
              <h3 class="title titlesize-25">Zouk mosbeh</h3>
              <span class="description">
                <p></p>
              </span>
              <span class="price text_align_right"></span>
            </div>
            <div class="swiper-slide slide">
              <div class="frame">
                <img alt="SASSINE" src="/assets/images/ImageNotAvailable.jpg" />
              </div>
              <h3 class="title titlesize-25">Sassine</h3>
              <span class="description">
                <p></p>
              </span>
              <span class="price text_align_right"></span>
            </div>
            <div class="swiper-slide slide">
              <div class="frame">
                <img alt="ACHRAFIEH" src="/assets/images/ImageNotAvailable.jpg" />
              </div>
              <h3 class="title titlesize-25">Achrafieh</h3>
              <span class="description">
                <p></p>
              </span>
              <span class="price text_align_right"></span>
            </div>
            <div class="swiper-slide slide">
              <div class="frame">
                <img alt="CHIR" src="/assets/images/ImageNotAvailable.jpg" />
              </div>
              <h3 class="title titlesize-25">Chir</h3>
              <span class="description">
                <p></p>
              </span>
              <span class="price text_align_right"></span>
            </div>
            <div class="swiper-slide slide">
              <div class="frame">
                <img alt="CITY CENTER" src="/assets/images/ImageNotAvailable.jpg" />
              </div>
              <h3 class="title titlesize-25">City center</h3>
              <span class="description">
                <p></p>
              </span>
              <span class="price text_align_right"></span>
            </div>
            <div class="swiper-slide slide">
              <div class="frame">
                <img alt="CITY MALL" src="/assets/images/ImageNotAvailable.jpg" />
              </div>
              <h3 class="title titlesize-25">City mall</h3>
              <span class="description">
                <p></p>
              </span>
              <span class="price text_align_right"></span>
            </div>
            <div class="swiper-slide slide">
              <div class="frame">
                <img alt="JBEIL SOUK" src="/assets/images/ImageNotAvailable.jpg" />
              </div>
              <h3 class="title titlesize-25">Jbeil souk</h3>
              <span class="description">
                <p></p>
              </span>
              <span class="price text_align_right"></span>
            </div>
            <div class="swiper-slide slide">
              <div class="frame">
                <img alt="DIK EL MEHDE" src="/assets/images/ImageNotAvailable.jpg" />
              </div>
              <h3 class="title titlesize-25">Dik el mehde</h3>
              <span class="description">
                <p></p>
              </span>
              <span class="price text_align_right"></span>
            </div>
            <div class="swiper-slide slide">
              <div class="frame">
                <img alt="FARAYA" src="/assets/images/ImageNotAvailable.jpg" />
              </div>
              <h3 class="title titlesize-25">Faraya</h3>
              <span class="description">
                <p></p>
              </span>
              <span class="price text_align_right"></span>
            </div>
          </div>
        </div>
      </div>

      <div class="addonscaption icon-before titlesize-22 hide_icon">
        <p>
          <span
            >ORDER NOW FROM ANY BRANCH BY DIALING
            <a class="red_text" title="Phone" href="tel:1592">1592</a></span
          >
        </p>
      </div>
    </section>
  </div>
</template>
<script>
import $ from 'jquery'
export default {
  mounted() {
    $('.foodswiper .swiper-slide').length > 1 &&
      ((foodswiper = new Swiper('.foodswiper', {
        speed: 1e3,
        loop: !1,
        slidesPerView: 'auto',
        preload: !0,
        keyboardControl: !0,
        onInit: function (e) {
          $(window).width() > 750
            ? $('.foodswiper .swiper-wrapper').removeClass('do_not_swipe')
            : $('.foodswiper .swiper-wrapper').addClass('do_not_swipe'),
            ShowHideArrows('.foodswiper', '.foodcontainer .arrows')
        },
        onSlideChangeStart: function (e) {
          e.startAutoplay()
        },
        onSlideChangeEnd: function (e) {
          e.startAutoplay()
        },
      })),
      $('.foodcontainer .rightarrow').click(function () {
        foodswiper.slideNext()
      }),
      $('.foodcontainer .leftarrow').click(function () {
        foodswiper.slidePrev()
      }))
  },
}
</script>
