<template>
  <div id="container" class="clearfix">
    <section class="abouttext centervertical">
      <div class="valigncontent">
        <h2 class="title titlesize-45 icon-title">WHO WE ARE?</h2>
        <span class="description">
          <p>
            <span
              >Malak al Tawouk Restaurant is the most popular Tawouk restaurant
              chain, specializing in original recipes, providing Professional
              management and offering great service.<br />

              <span class="quote"
                >EVERY DAY, THOUSANDS OF CUSTOMERS ARE SERVED AROUND OUR POPULAR
                CHAINS.</span
              > </span
            ><br /><span
              >We are operating in different regions inside Lebanon and
              developing new branches in the Middle-East. Malak al Tawouk
              Restaurant chain is the undisputed leader in the Lebanese fast
              food category serving fresh, great tasting, made-for-you
              sandwiches, Salads and wide choices of delicious food items.
              Healthful and fresh ingredients have made us a popular destination
              for Loyal consumers. Founded in 1996, we have become the
              destination for fast food serving And the pioneers in delivery
              service. Currently we are operating a network of company- owned
              and Franchise-owned restaurants in Lebanon and international
              Markets.</span
            >
          </p>
          <p>
            <strong>We are an ISO 22000:2005 Certified company</strong
            ><br /><br />
          </p>
        </span>
      </div>
      <span class="arrow"></span>
    </section>
    <section class="aboutinspiration centervertical">
      <h3 class="quote valigncontent">
        OUR VISION ILLUSTRATES A COMPANY OF EXCEPTIONAL PEOPLE ON A MISSION TO
        STAY THE BEST IN THE INDUSTRY.
      </h3>
    </section>
  </div>
</template>
