<template>
  <div id="container" class="clearfix">
    <section class="openings">
      <h2 class="maintitle titlesize-45">WE ARE HIRING!</h2>
      <span class="description">
        <p>
          If you’re looking for a chance at a fast-paced, hands-on career,
          you’ve come to the right place. The job opportunities in Malak Al
          Tawouk restaurants are challenging and fun.
        </p>
        <p>Send your CV to jobs@malakaltawouk.com</p>
      </span>
      <ul class="openinglisting icon-before">
        <li @click="handleform('KITCHEN CREW')" id="careerid_1">
          <h2 class="text">KITCHEN CREW</h2>
        </li>
        <li @click="handleform('RESTAURANT MANAGER')" id="careerid_2">
          <h2 class="text">RESTAURANT MANAGER</h2>
        </li>
        <li @click="handleform('CALL CENTER AGENT')" id="careerid_3">
          <h2 class="text">CALL CENTER AGENT</h2>
        </li>
        <li @click="handleform('CASHIER')" id="careerid_4">
          <h2 class="text">CASHIER</h2>
        </li>
        <li @click="handleform('DELIVERY DRIVER')" id="careerid_5">
          <h2 class="text">DELIVERY DRIVER</h2>
        </li>
      </ul>
    </section>
    <Careersform @formcontrol="setOpen" v-if="isOpen" :title="getTitle" />
  </div>
</template>
<script setup>
import Sidebar from '@/components/Partials/Sidebar.vue'
import { computed } from '@vue/runtime-core'
import Careersform from '@/components/Partials/Careersform.vue'
</script>
<script>
export default {
  data() {
    return {
      isOpen: false,
      title: '',
    }
  },
  computed: {
    Captcha() {
      return SITEKEY
    },
    getTitle() {
      return this.title
    },
  },
  methods: {
    handleform(title) {
      this.title = title
      this.isOpen = true
    },
    setOpen(isOpen) {
      this.isOpen = isOpen
    },
  },
}
</script>
