<script setup>
import Header from "@/components/Partials/Header.vue";
</script>
<template>
  <div id="fb-root"></div>
  <input type="hidden" id="routeUrl" name="routeUrl" value="/" />
  <h1 class="hidden">Malak Al Tawouk</h1>
  <div id="wrapper" :class="{ 'overflow-visible': currentRouteName.includes('Menu')}">
    <Header />
    <router-view />
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  computed: {
    ...mapGetters(["allItems"]),
    currentRouteName() {
        return this?.$route?.name ?? "";
    },
},
  methods: {
    ...mapActions(["fetchAllItems"]),
  },
  beforeMount() {
    if (this.allItems === null || this.allItems.length === 0) {
      this.fetchAllItems();
    }
  },
};
</script>
