<template>
  <nav class="menu">
    <div class="swiper-container menucontainer faded">
      <div class="swiper-wrapper do_not_swipe">
        <router-link
          title="About Us"
          class="swiper-slide slide menuitem"
          :to="{ name: 'About' }"
        >
          <span class="scaledicon icon-before-after"></span>
          <span class="title">About</span>
        </router-link>
        <router-link
          title="Menu"
          class="swiper-slide slide menuitem"
          :to="{ name: 'Menu.sandwiches' }"
        >
          <span class="scaledicon icon-before-after"></span>
          <span class="title">Menu</span>
        </router-link>
        <!-- <router-link
          title="Franchise"
          class="swiper-slide slide menuitem"
          :to="{ name: 'Franchise' }"
        >
          <span class="scaledicon icon-before-after"></span>
          <span class="title">Franchise</span>
        </router-link> -->
        <router-link
          title="Branches"
          class="swiper-slide slide menuitem"
          :to="{ name: 'Branches' }"
        >
          <span class="scaledicon icon-before-after"></span>
          <span class="title">Branches</span>
        </router-link>
        <router-link
          title="Careers"
          class="swiper-slide slide menuitem"
          :to="{ name: 'Careers' }"
        >
          <span class="scaledicon icon-before-after"></span>
          <span class="title">Careers</span>
        </router-link>
        <router-link
          title="Contact Us"
          class="swiper-slide slide menuitem"
          :to="{ name: 'Contact' }"
        >
          <span class="scaledicon icon-before-after"></span>
          <span class="title">Contact</span>
        </router-link>
      </div>
    </div>
  </nav>
</template>
<script>
export default {
  name: 'Sidebar',
}
</script>
