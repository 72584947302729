<template>
  <section ref="form" class="applypopup centervertical open">
    <div class="valigncontent">
      <span class="closecareersform closeform" @click="handleClose()"></span>
      <form
        @submit.prevent="handlesubmit"
        ref="contactform"
        id="careers_form"
        class="careersform icon-before"
      >
        <h3 class="title titlesize-25">{{ getTitle }}</h3>
        <div class="inputfield">
          <label class="inputlabel" for="careersfullname">Full Name</label>
          <input class="input" name="name" required v-model="name" />
        </div>
        <div class="inputfield">
          <label class="inputlabel" for="careersemail">Email</label>
          <input
            class="input"
            type="email"
            name="email"
            required
            v-model="email"
          />
        </div>
        <div class="inputfield">
          <label class="inputlabel" for="careersphone">Phone</label>
          <input
            class="input numbersonly"
            name="phone"
            required
            v-model="phone"
          />
        </div>
        <div class="inputfield">
          <label class="inputlabel" for="careerseducation">Education</label>
          <input class="input" name="education" v-model="education" />
        </div>
        <div class="inputfield">
          <label class="inputlabel" for="careerscv">Upload C.V</label>
          <input
            class="input fileInputPadding"
            type="file"
            name="cv_file"
            accept=".pdf,.doc,.docx,"
            required
            @change="onFileChange"
          />
        </div>
        <div class="inputfield area">
          <textarea
            class="textarea"
            name="message"
            id="careersmessage"
            placeholder="Message"
            v-model="message"
          ></textarea>
        </div>
        <vue-recaptcha
          v-show="showRecaptcha"
          sitekey="{{SITEKEY}}"
          size="normal"
          theme="light"
          hl="tr"
          @verify="recaptchaVerified"
          @expire="recaptchaExpired"
          @fail="recaptchaFailed"
          ref="vueRecaptcha"
        >
        </vue-recaptcha>
        <input type="submit" class="blackbtn send loaderbtn" />
      </form>
      <span class="careersmessage titlesize-45"
        >Thank You, We Will Get Back To You Soon!</span
      >
    </div>
  </section>
</template>
<script>
import vueRecaptcha from 'vue3-recaptcha2'
import { SITEKEY } from '@/constants/recaptcha'
import Careers from '@/forms/Careers'
export default {
  props: {
    isOpen: Boolean,
    title: String,
  },
  components: {
    vueRecaptcha,
  },
  data() {
    return {
      isOpen: this.isOpen,
      title: this.title,
      showRecaptcha: false,
      SITEKEY: SITEKEY,

      // form data
      name: '',
      email: '',
      phone: '',
      message: '',
      education: '',
      cv: null,
    }
  },
  computed: {
    getTitle() {
      return this.title
    },
  },
  methods: {
    handlesubmit() {
      Careers(
        this.title,
        this.name,
        this.email,
        this.phone,
        this.education,
        this.cv,
        this.message
      )
      this.handleClose()
    },
    onFileChange(e) {
      var files = e.target.files || e.dataTransfer.files
      if (!files.length) return
      this.cv = files[0]
    },
    handleClose() {
      this.$refs.form.classList.remove('open')
      this.$emit('formcontrol', false)
    },
    recaptchaVerified(response) {},
    recaptchaExpired() {
      this.$refs.vueRecaptcha.reset()
    },
    recaptchaFailed() {},
  },
  name: 'Careersform',
}
</script>
