<script setup>
defineProps({
  title: String,
});
</script>
<template>
  <div class="swiper-container menucategories">
    <div class="swiper-wrapper">
      <router-link
        class="swiper-slide slide"
        :to="{ name: 'Menu.sandwiches' }"
        :class="{ active: title === 'sandwiches' }"
      >
        <img
          alt="SANDWICHES"
          class="categoryicon_rollover"
          src="/assets/images/sandwiches.png"
        />
        <h2 class="title icon-before">SANDWICHES</h2>
      </router-link>
      <router-link
        class="swiper-slide slide"
        :to="{ name: 'Menu.burgers' }"
        :class="{ active: title === 'burgers' }"
      >
        <img
          alt="BURGERS"
          class="categoryicon_rollover"
          src="/assets/images/burger_category.png"
        />
        <h2 class="title icon-before">BURGERS</h2>
      </router-link>
      <router-link
        class="swiper-slide slide"
        :to="{ name: 'Menu.platters' }"
        :class="{ active: title === 'platters' }"
      >
        <img
          alt="PLATTERS"
          class="categoryicon_rollover"
          src="/assets/images/platters_category_1.png"
        />
        <h2 class="title icon-before">PLATTERS</h2>
      </router-link>
      <router-link
        class="swiper-slide slide"
        :to="{ name: 'Menu.boxanddip' }"
        :class="{ active: title === 'boxanddip' }"
      >
        <img
          alt="BOX AND DIP"
          class="categoryicon_rollover"
          src="/assets/images/box.png"
        />
        <h2 class="title icon-before">BOX AND DIP</h2>
      </router-link>
      <router-link
        class="swiper-slide slide"
        :to="{ name: 'Menu.sidesalads' }"
        :class="{ active: title === 'sidesalads' }"
      >
        <img
          alt="SIDE SALADS"
          class="categoryicon_rollover"
          src="/assets/images/salad.png"
        />
        <h2 class="title icon-before">SIDE SALADS</h2>
      </router-link>
      <router-link
        class="swiper-slide slide"
        :class="{ active: title === 'beverages' }"
        :to="{ name: 'Menu.beverages' }"
      >
        <img
          alt="BEVERAGES"
          class="categoryicon_rollover"
          src="/assets/images/soda.png"
        />
        <h2 class="title icon-before">BEVERAGES</h2>
      </router-link>
    </div>
  </div>
</template>
<script>
export default {
  name: "MenuTopBar",
  mounted(){
    var initialslide, containerWidth, itemsWidthSum
        $('.menucategories .swiper-slide').length > 1 &&
      ((initialslide = 0),
      $('#categoryinitialslide').length > 0 &&
        (initialslide = $('#categoryinitialslide').val()),
      (menucategoriesswiper = new Swiper('.menucategories', {
        speed: 1e3,
        loop: !1,
        slidesPerView: 'auto',
        preload: !0,
        noSwipingClass: 'do_not_swipe',
        initialSlide: initialslide,
        preventClicks: !0,
        preventClicksPropagation: !1,
        
        onSlideChangeStart: function (e) {
          e.startAutoplay()
        },
        onSlideChangeEnd: function (e) {
          e.startAutoplay()
        },
      })))
  }
};
</script>
