<template>
  <div class="foodcontainer centervertical">
    <div class="swiper-container foodswiper valigncontent swiper-container-horizontal">
      <div class="swiper-wrapper">
        <div class="swiper-slide slide" v-for="(item, index) in filteredCategory" :key="index">
          <div class="frame">
            <img :alt="item.name" :src="item.image_path" />
          </div>
          <div style="display: flex; flex-direction: column; gap: 2px;">

              <h3 class="title titlesize-25">{{ item.name }}</h3>
                          <span class="price" style="padding-left: 18px !important; padding-right: 0px !important;">{{ item.price_large && 'Regular:' }} {{ numberWithCommas(item.price) }} LBP<br><span
                v-if="item.price_large" >Large: {{ numberWithCommas(item.price_large) }} LBP</span></span>
              <span class="description">
                <p>{{ item.description }}</p>
              </span>


          </div>
          <span class="price mobileprice">{{ item.price_large && 'Regular:' }} {{ numberWithCommas(item.price) }}
            LBP<br><span v-if="item.price_large" class="mobileprice">Large: {{ numberWithCommas(item.price_large) }}
              LBP</span></span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { isProxy, toRaw } from 'vue'
import $ from 'jquery'
let swipers
export default {
  props: {
    selectedCategory: String,
  },
  data() {
    return {
      filtered: [],
    }
  },
  computed: {
    ...mapGetters(['allItems']),
    filteredCategory() {
      const Category = this.allItems.filter(
        (a) => a.name.toLowerCase() === this.selectedCategory.toLowerCase()
      )
      let filtered = Category.map((a) => a.items)
      if (isProxy(filtered[0])) {
        var rawData = toRaw(filtered[0])
      }
      this.filtered = rawData
      return rawData
    },
  },
  methods: {
    numberWithCommas(x = "") {
      if (x !== null) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
      return "";
    },
  },
  updated() {
    swipers = InitializeSlideshow()
  },
  mounted() {
    // if (this.filtered.items > 4) {
    //   this.$refs.Prev.classList.remove = "hidden";
    //   this.$refs.Next.classList.remove = "hidden";
    // }
    $(window).resize((_) => {
      $(window).width() > 766
        ? $('.foodswiper .swiper-wrapper').removeClass('do_not_swipe')
        : $('.foodswiper .swiper-wrapper').addClass('do_not_swipe')
      ShowHideArrows('.foodswiper', '.foodcontainer .arrows')
    })
    swipers = InitializeSlideshow()
  },
  unmounted() {
    destroySwipers(swipers)
  },
}

function InitializeSlideshow() {
  let swipers = []
  if ($('.foodswiper .swiper-slide').length > 1) {
    const foodswiper = new Swiper('.foodswiper', {
      speed: 1e3,
      loop: !1,
      slidesPerView: 'auto',
      preload: !0,
      keyboardControl: !0,
      onInit: function (e) {
        $(window).width() > 766
          ? $('.foodswiper .swiper-wrapper').removeClass('do_not_swipe')
          : $('.foodswiper .swiper-wrapper').addClass('do_not_swipe'),
          ShowHideArrows('.foodswiper', '.foodcontainer .arrows')
      },
      onSlideChangeStart: function (e) {
        e.startAutoplay()
      },
      onSlideChangeEnd: function (e) {
        e.startAutoplay()
      },
    })
    swipers.push(foodswiper)
    $('.foodcontainer .rightarrow').click(function () {
      foodswiper.slideNext()
    })
    $('.foodcontainer .leftarrow').click(function () {
      foodswiper.slidePrev()
    })
  }
  return swipers
}
function destroySwipers(swipers) {
  if (swipers.length > 0) {
    swipers.forEach((swiper) => swiper.destroy())
  }
}
</script>
