<template>
  <div id="container" class="clearfix">
    <section class="homeslideshow">
      <div class="swiper-container hometitleswiper">
        <div class="swiper-wrapper">
          <div class="swiper-slide slide">
            <h2 class="hidden">Juan الغول</h2>
            <a class="title titlesize-45">Juan الغول</a>
          </div>
          <div class="swiper-slide slide">
            <h2 class="hidden">Cheesy عزيزي</h2>
            <a class="title titlesize-45">Cheesy عزيزي</a>
          </div>
        </div>
      </div>

      <div class="mainslideshowcontainer centervertical">
        <div class="swiper-container mainslideshow">
          <div class="swiper-wrapper">
            <a class="swiper-slide slide containimage centervertical">
              <img
                alt="Juan الغول"
                class=""
                src="/assets/images/juan.png"
              />
            </a>
            <a class="swiper-slide slide containimage centervertical">
              <img
                alt="Cheesy عزيزي"
                class=""
                src="/assets/images/cheesy.png"
              />
            </a>
          </div>
          <div class="swiper-pagination"></div>
        </div>
      </div>
      <span
        class="expandeslideshow squaregoto"
        onclick="ToggleCustomClass('#container', 'fullscreenshow')"
      ></span>

      <div class="slideshowinfostripe">
        <div class="swiper-container slideshowinfo">
          <div class="swiper-wrapper">
            <div class="swiper-slide slide centervertical"></div>
            <div class="swiper-slide slide centervertical"></div>
          </div>
        </div>
      </div>
    </section>

    <div class="homegrid">
      <div class="gridtop">
        <section
          class="discovermenu"
          style="
            background-image: url('/assets/images/bigchickenburger.jpg');
          "
        >
          <span class="rolloverlayer">
            <span class="caption">DISCOVER</span>
          </span>
          <span class="underlinedicon centervertical">
            <img
              style="display: none"
              alt="DISCOVER"
              src="https://kms.malakaltawouk.com/assets/ResizedImages/90/90/inside/"
            />
          </span>
          <span class="title titlesize-40">Big Chicken </span>
          <h2 class="subtitle titlesize-30">Burger</h2>
          <router-link
            
            class="gotomenu squaregoto"
            :to="{ name: 'Menu.sandwiches' }"
          ></router-link>
        </section>

        <section class="ordernow centervertical">
          <span class="rolloverlayer">
            <span class="caption">Hungry?</span>
          </span>
          <div class="valigncontent">
            <span class="underlinedicon centervertical">
              <img alt="Hungry?" src="/assets/img/phone.png" />
            </span>
            <h2 class="title titlesize-40">Order Now</h2>
            <div class="swiper-container phoneswiper">
              <div class="swiper-wrapper">
                <span class="swiper-slide slide swiper-slide-active">
                  <a

                    class="titlesize-30 phonenumber"
                    href="tel:1592"
                    >1592</a
                  >
                </span>
              </div>
            </div>
          </div>
        </section>
      </div>

      <section class="locate centervertical">
        <div class="valigncontent">
          <span class="branchescount">35</span>
          <h2 class="title titlesize-30">Branches in lebanon</h2>
          <router-link

            class="yellowbtn locateus"
            :to="{ name: 'Branches' }"
            >Locate us</router-link
          >
        </div>
      </section>
    </div>
    <aside class="sidestripe">
      <span class="text titlesize-25">Order<br />Now</span>
      <div class="stripephoneswiper swiper-container">
        <div class="swiper-wrapper">
          <a

            class="swiper-slide phone titlesize-29 swiper-slide-active"
            href="tel:1592"
            ><span class="titlesize-44 block">1592</span></a
          >
        </div>
      </div>
      <span
        class="closeslideshow icon-before"
        onclick="ToggleCustomClass('#container', 'fullscreenshow')"
      ></span>
    </aside>
  </div>
</template>
