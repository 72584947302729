import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Branches from '../views/Branches.vue'
import Careers from '../views/Careers.vue'
import Contact from '../views/Contact.vue'
import Franchise from '../views/Franchise.vue'
import About from '../views/About.vue'
import Beverages from '@/views/Menus/Beverages.vue'
import BoxAndDip from '@/views/Menus/BoxAndDip.vue'
import Burgers from '@/views/Menus/Burgers.vue'
import Platters from '@/views/Menus/Platters.vue'
import Sandwiches from '@/views/Menus/Sandwiches.vue'
import SideSalads from '@/views/Menus/SideSalads.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
  },
  {
    path: '/Branches',
    name: 'Branches',
    component: Branches,
  },
  {
    path: '/Careers',
    name: 'Careers',
    component: Careers,
  },
  {
    path: '/Contact',
    name: 'Contact',
    component: Contact,
  },
  // {
  //   path: "/Franchise",
  //   name: "Franchise",
  //   component: Franchise,
  // },
  {
    path: '/About',
    name: 'About',
    component: About,
  },
  {
    path: '/Sandwiches',
    name: 'Menu.sandwiches',
    component: Sandwiches,
  },
  {
    path: '/Beverages',
    name: 'Menu.beverages',
    component: Beverages,
  },
  {
    path: '/BoxAndDip',
    name: 'Menu.boxanddip',
    component: BoxAndDip,
  },
  {
    path: '/Platters',
    name: 'Menu.platters',
    component: Platters,
  },
  {
    path: '/SideSalads',
    name: 'Menu.sidesalads',
    component: SideSalads,
  },
  {
    path: '/Burgers',
    name: 'Menu.burgers',
    component: Burgers,
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

export default router
