<script setup>
import Sidebar from "@/components/Partials/Sidebar.vue";
</script>
<template>
  <header>
    <router-link title="Malak Al Tawouk" :to="{name : 'home'}" class="logo containimage" style="display:flex; justify-content:center; align-items:center;">
      <img alt="Malak Al Tawouk" src="/assets/images/logo.png" />
    </router-link>
    <span class="mobilemenutoggle" onclick="ToggleOpen('.mobilemenutoggle')">
      <span></span>
    </span>

    <Sidebar />

    <div class="social">
      <a
        title="Facebook"
        class="socialicon nodecoration font-before fb"
        href="https://www.facebook.com/MalakAlTawoukLeb/"
        target="_blank"
      ></a>
      <div class="share socialicon nodecoration sharethis icon-before-after">
        <div class="addthis_toolbox addthis_default_style">
          <a class="addthis_counter addthis_pill_style"></a>
        </div>
      </div>
    </div>
  </header>
</template>
<script>
export default {
  name: "Header",
};
</script>
